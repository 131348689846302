import { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

interface CoinDropdownProps {
    selectedCoin: string;
    currencyOptions: { code: string; symbol: string; image: string }[];
    onSelect: (code: string) => void;
}

const CoinDropdown: React.FC<CoinDropdownProps> = ({ selectedCoin, currencyOptions, onSelect }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const toggleCoinDropdown = () => {
        setIsDropdownOpen((prev) => !prev);
    };

    const handleCoinSelect = (code: string) => {
        onSelect(code);
        setIsDropdownOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="flex items-center space-x-3 mr-2">
            {/* <img className="hidden" src={`/currency-kas.png`} alt={`none`} />
            <img className="hidden" src={`/currency-btc.png`} alt={`none`} /> */}
            <button
                id="dropdownUsersButton"
                className="text-white bg-transparent gap-2 hover:bg-blue-950 hover:bg-opacity-15 border border-white font-medium rounded-lg text-xs md:text-sm px-1  md:px-3 py-1 md:py-2 inline-flex items-center justify-between"
                onClick={toggleCoinDropdown}
            >
                <img
                    className="w-4 md:w-6 h-4 md:h-6 mr-1 md:mr-2 rounded-full"
                    src={
                        selectedCoin === 'BTC'
                            ? '/assets/currency-btc.png'
                            : selectedCoin === 'KAS'
                                ? '/assets/currency-kas.png'
                                : '/assets/currency-alph.png'
                    }
                    alt={`${selectedCoin} icon`}
                />
                <span className='text-xs md:text-sm'>{selectedCoin}</span>
                <FontAwesomeIcon icon={faChevronDown} />
            </button>

            {isDropdownOpen && (
                <div ref={dropdownRef} className="fixed top-14 right-5 z-50 my-4 w-48 bg-white rounded-lg shadow">
                    <ul className="py-2 text-gray-700">
                        {currencyOptions.map((option) => (
                            <li key={option.code}>
                                <button
                                    className="flex items-center w-full px-4 py-2 hover:bg-gray-100"
                                    onClick={() => handleCoinSelect(option.code)}
                                >
                                    <img className="w-6 h-6 mr-2 rounded-full" src={option.image} alt={`${option.code} icon`} />
                                    {option.symbol}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default CoinDropdown;
