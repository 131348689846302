import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import SynchronizedAreaChart2 from '../../Elements/Chart2';
import { CoinContext } from '../../../context/CoinContext';
import ToggleSwitch from '../../Elements/ToggleSwitch';
import LoadingHomeSkeleton from '../../Elements/HomeLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { DotLoader } from '../../Elements/LogoLoader';

export interface HomeData {
    hashRate24: string;
    revenueYestersay: string;
    revenueTodayEst: string;
    minersOnline: string;
    minersOffline: string;
    networkData: {
        netHashRate: string;
        dailyRevenuePerTh: string;
        dailyRevenuePerThInDollar: string;
        difficulty: string;
        coinPrice: string;
    };
}

interface DataItem {
    time: string;
    hashrate: number;
}

interface ApiResponseItem {
    timeStamp: number;
    hash: number;
}

interface Payout {
    rewardDate: string;
    coin: string;
    avgHash: number;
    reward: number;
    transactionStatus: string;
    amount:number;
}

interface BalanceData {
    totalBalance: number;
    coin: string;
    rewards: any[];
    payouts: Payout[];
}
interface NavigateToPasswordProps {
    NavigateToPassword: () => void;
}

const HomeData: React.FC<NavigateToPasswordProps> = ({ NavigateToPassword }) => {
    const [dataPeriod, setDataPeriod] = useState("1");
    const [chartData, setChartData] = useState<DataItem[]>([]);
    const [listingData, setListingData] = useState<HomeData | null>(null);
    const [balance, setBalance] = useState<any>("-");
    const [loading, setLoading] = useState(false)
    const [hashrate, setHashrate] = useState<number | null>()
    const [totalPayout, setTotalPayout] = useState<number | null>()
    const [userWalletCheck, setUserWalletCheck] = useState<boolean | null>(null)
    const [toggleSwitchActive, setToggleSwitchActive] = useState(false)

    const coinContext = useContext(CoinContext);

    const fetchHomeData = (coin: string) => {
        setLoading(true)
        axios.get(`/pool/dashboard-info?coin=${coin}`)
            .then(response => {
                setListingData(response.data);
                setLoading(false)
            })
            .catch(error => {
                console.error('Error fetching home data:', error);
                setLoading(false)
            });
    };

    const fetchBalance = (coin: string) => {
        axios.get(`/pool/reward?coin=${coin}`)
            .then((response: { data: BalanceData }) => {
                setBalance(response?.data?.totalBalance);
                const totalPayoutSum = response.data.payouts.reduce((acc: number, payout: Payout) => {
                    if (payout.transactionStatus === "TRANSFER_COMPLETED") {
                        return acc + payout.amount;
                    }
                    return acc;
                }, 0);

                setTotalPayout(totalPayoutSum)
            })
            .catch((error: any) => {
                console.error('Error fetching balance:', error);
            });
    };

    const walletCheck = (coin: string) => {
        setUserWalletCheck(null)
        axios.get(`/pool/wallet?coin=${coin}`)
            .then((response: { data: any }) => {
                setUserWalletCheck(response.data.length === 0 ? true : false)
            })
            .catch((error: any) => {
                console.error('Error fetching account details:', error);
            });
    }

    const fetchHomeChartData = (dataPeriod: string, coin: string, steps: string) => {
        setToggleSwitchActive(true)
        axios.get(`/pool/date-hash-coord-redis?hours=${dataPeriod}&steps=${steps}&coin=${coin}`)
            .then(response => {
                const formattedData: DataItem[] = response.data.map((n: ApiResponseItem) => ({
                    time: formatTimeLabel(n.timeStamp),
                    hashrate: n.hash.toFixed(1),
                }));
                const totalHashrate = response.data.reduce((sum: number, item: ApiResponseItem) => sum + item.hash, 0);
                const dataLength = response.data.length;
                const calculatedHashrate = totalHashrate / dataLength;

                setHashrate(calculatedHashrate);
                setChartData(formattedData);
                setToggleSwitchActive(false)
            })
            .catch(error => {
                console.error('Error fetching chart data:', error);
                setChartData([]);
                setToggleSwitchActive(false)
            });
    };

    const formatTimeLabel = (timestamp: number): string => {
        return new Date(timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const roundUpToEightDecimals = (num: any): string => {
        return typeof num == 'string' ? num : num.toFixed(8);
    };

    useEffect(() => {
        if (coinContext) {
            const coin = coinContext.selectedCoin;
            Promise.all([
                fetchHomeData(coin),
                fetchBalance(coin),
                fetchHomeChartData(dataPeriod, coin, dataPeriod === "1" ? "1m" : "15m"),
                walletCheck(coin)
            ]).then(() => {
            }).catch((error) => {
                console.error("Error fetching data:", error);
            });
        }
    }, [dataPeriod, coinContext]);


    return (
        <div className="md:py-8 pb-6">
            <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-2 m-5 md:gap-4">
                <DataCard loading={loading} title={`Total Payout (${coinContext?.selectedCoin})`} value={roundUpToEightDecimals(totalPayout ?? "-")} />
                <DataCard loading={loading} title={`Yesterday Revenue (${coinContext?.selectedCoin})`} value={roundUpToEightDecimals(listingData?.revenueYestersay ?? "-")} />
                <DataCard loading={loading} title={`Today's Est. Revenue (${coinContext?.selectedCoin})`} value={roundUpToEightDecimals(listingData?.revenueTodayEst ?? "-")} />
                <DataCard loading={loading} title={`Balance (${coinContext?.selectedCoin})`} value={roundUpToEightDecimals(balance)} />
            </div>


            <div className="my-2 md:my-4 bg-[#98A1AC] text-white p-4 md:rounded flex justify-around  md:justify-center items-center gap-y-2 sm:gap-x-4 text-center ">
                <StatusIndicator label="Online" value={listingData?.minersOnline} />
                <StatusIndicator label="Offline" value={listingData?.minersOffline} />
            </div>

            <div className='relative bg-white m-5 p-5 md:m-5 shadow-lg md:p-4 rounded-lg'>
                {toggleSwitchActive &&
                    <div className='absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 rounded-lg'>
                        <DotLoader />
                    </div>
                }
                <div className='flex-col md:flex-row justify-between items-center'>
                    
                    <div className='text-center md:text-left'>
                        <p className='text-lg md:text-2xl font-medium text-[#1B4788] mb-1'>{`Hashrate of last ${dataPeriod} hr`}</p>
                        <p className='text-xs md:text-sm font-medium text-gray-400 mb-2 pb-2'><span className='text-[#595E63]'>Average Hashrate :</span> {isNaN(Number(hashrate)) ? '0.00' : Number(hashrate).toFixed(2)} TH/s </p>
                    </div>
                    <div className="flex border w-36 md:w-40 m-1 mx-auto md:mx-0 md:ml-auto p-1 bg-[#0F6BAB] rounded-full text-xs md:text-sm">
                        <ButtonToggle
                            label="1 hour"
                            isActive={dataPeriod === '1'}
                            onClick={() => setDataPeriod("1")}
                            className="rounded-full"
                            toggleSwitchActive={toggleSwitchActive}
                        />
                        <ButtonToggle
                            label="24 hours"
                            isActive={dataPeriod === '24'}
                            onClick={() => setDataPeriod("24")}
                            className="rounded-full"
                            toggleSwitchActive={toggleSwitchActive}
                        />
                    </div>
                </div>
                <SynchronizedAreaChart2 data={chartData} />
            </div>
            {userWalletCheck &&
                <div className="my-2 md:my-4 text-center shadow-lg rounded-lg">
                    <button onClick={NavigateToPassword} className="bg-white gap-2 hover:bg-gray-100 flex items-center justify-center font-semibold py-2 px-4 w-full rounded shadow text-red-400">
                        <FontAwesomeIcon icon={faExclamationTriangle} style={{ fontWeight: '900', fontSize: '17px' }} />
                        {coinContext?.selectedCoin} WALLET MISSING
                    </button>
                </div>
            }
        </div>
    );
};

const DataCard: React.FC<{ title: string; value: any; loading: boolean }> = ({ title, value, loading }) => (
    <div className="bg-white p-2 md:p-6 text-blue-900 rounded-lg shadow-lg text-center flex-row items-center justify-center">
        <p className="text-sm md:text-lg">{title}</p>
        <p className="text-sm md:text-lg font-bold">{value}</p>
    </div>
);

const StatusIndicator: React.FC<{ label: string; value: string | undefined }> = ({ label, value }) => (
    <div className="flex items-center text-sm">
        <span className='font-bold'>{label}</span>
        <span className="ml-1">|</span>
        <span className="ml-1 font-bold">{(typeof value === 'number' && !isNaN(value)) ? value : "0"}</span>
    </div>
);


const ButtonToggle: React.FC<{ label: string; isActive: boolean; onClick: () => void; className: string; toggleSwitchActive: boolean }> = ({ label, isActive, onClick, className, toggleSwitchActive }) => (
    <button
        className={`px-1 py-1 w-full text-[#0F6BAB] ${isActive ? 'bg-white text-[#0F6BAB]' : 'bg-transparent text-white'} transition-all duration-300 ease-in-out ${className}`}
        disabled={isActive}
        onClick={() => !toggleSwitchActive && onClick()}
    >
        {label}
    </button>
);

export default HomeData;
